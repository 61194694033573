<template>
  <!-- assign -->
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    max-height="600px"
    max-width="400px"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn
        :x-small="xsmall"
        :small="small"

        rounded
        depressed
        color="light_grey dark_grey--text  "
        v-bind="attrs"
        v-on="{ ...menu }"
      >
        <v-icon
          :x-small="xsmall"
          :small="small"
          :class="{
            'pr-1': selectedUsers.length,
          }"
          >mdi-account</v-icon
        >

        <userAvatarGroup
          :limit="1"
          :users="selectedUsers"
          :size="avatarSize"
          :disabledMenu="true"
          :avatar_border="false"
        />
      </v-btn>
    </template>
    <v-card flat class="px-2">
      <v-subheader>{{ $t("textEditor.assignTask") }}:</v-subheader>

      <searchAndSelectUsers
        :selectedUsersIds="selectedUsers.map((e) => e.id)"
        :selectedMessage="$t('textEditor.assignedToTask')"
        @onUserSelected="onUserSelected"
        @onUserUnselected="onUserUnselected"
      />
      <v-list dense two-lines class="py-0">
        <userItem v-for="user in selectedUsers" :key="user.id" :user="user">
          <template v-slot:action>
            <v-btn icon @click="onUserUnselected(user)">
              <v-icon color="error" small>mdi-account-minus</v-icon>
            </v-btn>
          </template>
        </userItem></v-list
      >
    </v-card>
  </v-menu>
</template>

<script>
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import userItem from "@/components/users/userItem";
import userAvatarGroup from "@/components/shared/userAvatarGroup";

export default {
  name: "selectUsers",
  components: { searchAndSelectUsers, userItem, userAvatarGroup },
  mixins: [],
  props: {
    selectedUsers: {
      type: Array,
      required: true,
    },
    xsmall: {
      type: Boolean,
      default: false,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      avatarSize: 22,
    };
  },
  methods: {
    onUserSelected(user) {
      this.$emit("onUserSelected", user.id);
    },
    onUserUnselected(user) {
      this.$emit("onUserUnselected", user.id);
    },
  },
  watch: {},
  created() {
    if (this.xsmall) {
      this.avatarSize = 14;
    }
    if (this.small) {
      this.avatarSize = 18;
    }
  },
};
</script>

<style scoped>
</style>
