<template>
  <!-- Due Date -->

  <v-menu
    v-model="showDateMenu"
    offset-y
    :ref="'menu_date'"
    :close-on-content-click="true"
    :return-value.sync="selectedDate"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <!-- <v-tooltip top >
          <template v-slot:activator="{ on: tooltip }"> -->
      <v-btn
        :x-small="xsmall"
        :small="small"
        rounded

        depressed
        color="light_grey dark_grey--text "
        v-bind="attrs"
        v-on="{ ...menu }"
      >
        <v-icon
          :x-small="xsmall"
          :small="small"
          :class="{
            'pr-2': date,
          }"
          >mdi-calendar-check</v-icon
        >
        {{ date ? friendlyDate(date) : "" }}
      </v-btn>
      <!--  </template>

          <span> {{ $t("textEditor.dueDate") }}</span>
        </v-tooltip> -->
    </template>
    <v-date-picker
      v-model="selectedDate"
      no-title
      scrollable
      :min="todaysDate"
      @change="
        $refs['menu_date'].save(selectedDate);
        $emit('dateSelected', selectedDate);
      "
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import date from "@/mixins/date";

export default {
  name: "selectDateChip",

  mixins: [date],
  props: {
    date: {
      type: Number,
      required: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showDateMenu: false,
      selectedDate: null,
      todaysDate: new Date().toISOString(),
    };
  },
  methods: {
    initSelectedDate() {
      let tmp = this.date ? this.date : this.todaysDate;
      this.selectedDate = new Date(tmp).toISOString().substr(0, 10);
    },
  },
  watch: {
    date() {
      this.initSelectedDate();
    },
  },
  created() {
    this.initSelectedDate();
  },
};
</script>

<style scoped>
</style>
