<template>
  <v-container
    fluid
    class="pa-0"
    style="height: 100vh; background-color: white"
  >
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.tasks") }}</b>
      </template>
    </headerToolbar>

    <div style="display: flex" class="pa-0 pl-0" v-if="selectedOrganisationId">
      <!-- FILTERS AND PAGINATED TABLE -->
      <div style="width: 100%">
        <v-container fluid class="pa-0">
          <v-row
            class="pa-2"
            style="height: 60px"
            wrap
            no-gutters
            align="center"
          >
            <!-- Done/ Not done filter -->
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  :color="selectedDoneFilter != selectedDoneFilterDefault ? 'button_blue' : 'button_text_grey'"
                  class="font-weight-medium mr-4"
                >
                  <v-icon small class="pr-1">mdi-check-circle-outline</v-icon
                  >{{ $t(doneFilters[selectedDoneFilter].title) }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in doneFilters"
                  :key="index"
                  @click="selectedDoneFilter = index"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      color="blue"
                      v-if="selectedDoneFilter == index"
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Assigned to filter -->
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  :color="selectedAssignedToFilter != selectedAssignedToFilterDefault ? 'button_blue' : 'button_text_grey'"
                  class="font-weight-medium mr-4"
                >
                  <v-icon small class="pr-1">mdi-account-outline</v-icon
                  >{{ $t(assignedToFilters[selectedAssignedToFilter].title) }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in assignedToFilters"
                  :key="index"
                  @click="selectedAssignedToFilter = index"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      color="blue"
                      v-if="selectedAssignedToFilter == index"
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Priority to filter -->
            <v-menu bottom offset-y min-width="240">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  :color="selectedPriorityFilter != selectedPriorityFilterDefault ? 'button_blue' : 'button_text_grey'"
                  class="font-weight-medium mr-4"
                >
                  <v-icon small class="pr-1">mdi-filter-variant</v-icon
                  >{{
                    $t("tasks.priority") +
                    ": " +
                    $t(priorityFilters[selectedPriorityFilter].title)
                  }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in priorityFilters"
                  :key="index"
                  @click="selectedPriorityFilter = index"
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      color="blue"
                      v-if="selectedPriorityFilter == index"
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

                  <task-priority
                    v-if="index > 0"
                    :value="item.value"
                    readonly
                  />
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Projects -->
            <v-menu
              bottom
              offset-y
              min-width="240"
              v-model="showProjectFiltersMenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  :text="selectedProjectFilter == ''"
                  :dark="selectedProjectFilter != ''"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :color="
                    selectedProjectFilter
                      ? selectedProjectFilter.color
                      : 'button_text_grey'
                  "
                  class="font-weight-medium mr-4"
                  :class="{
                    'ml-2': selectedProjectFilter != '',
                  }"
                >
                  <v-icon small class="pr-1">{{
                    selectedProjectFilter == ""
                      ? "mdi-folder-outline"
                      : "mdi-folder"
                  }}</v-icon
                  >{{
                    selectedProjectFilter
                      ? selectedProjectFilter.name
                      : $t("tasks.project")
                  }}
                </v-btn>
              </template>

              <v-list dense class="py-1">
                <v-list-item
                  v-for="(item, index) in projects"
                  :key="index"
                  @click="
                    selectedProjectFilter && selectedProjectFilter.id == item.id
                      ? (selectedProjectFilter = '')
                      : (selectedProjectFilter = item)
                  "
                >
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      small
                      color="blue"
                      v-if="
                        selectedProjectFilter &&
                        selectedProjectFilter.id == item.id
                      "
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>
                    <v-icon small class="pr-2" :color="item.color"
                      >mdi-folder</v-icon
                    >{{ item.name }}
                  </v-list-item-title>

                  <v-list-item-icon class="my-0">
                    <v-btn
                      style="top: 2px"
                      v-if="
                        selectedProjectFilter &&
                        selectedProjectFilter.id == item.id
                      "
                      @click.stop="
                        selectedProjectFilter = '';
                        showProjectFiltersMenu = false;
                      "
                      icon
                      color="red"
                    >
                      <v-icon small color="error">mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>

                <!-- No projects -->
                 <v-list-item v-if="projects.length == 0" disabled>

                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("projects.noProjects")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :label="$t('tasks.search')"
              outlined
              rounded
              solo
              flat
              dense
              class="denseTextField mr-4"
              prepend-inner-icon="mdi-magnify"
              width="200"
              hide-details
              clearable
            ></v-text-field>
          </v-row>
          <v-divider class="mx-4"></v-divider>
          <v-row wrap no-gutters>
            <v-col
              cols="12"
              class="pa-0 pt-2 px-4"
              style="height: calc(100vh - 135px); overflow: auto"
            >
              <paginatedTable
                :dense="true"
                v-if="currentUser && currentUser.id"
                :headers="headers"
                :graphqlQuery="getGraphqlQuery()"
                :refresh="refreshIndex"
                resourceName="feed_items"
                :forcedFooterText="$t('tasks.tasks')"
                :itemsPerPageAtInit="50"
                :filter="filter"
                useCustomEmptySlot
                :customSlots="[
                  'content',
                  'done',
                  //        'status',
                  //        'start_date',
                  //        'end_date',
                  //        'favorite',
                  'due_date',
                  'action',
                  'users',

                  'priority',
                ]"
                @clickOnRow="displayTaskDetails"
                cursorAsPointer
                @noResourceFetched="noResourceFetched = true"
              >
                <template v-slot:done="{ slotScope: { item } }">
                  <v-btn
                    small
                    icon
                    :color="item.done ? 'success' : '#aaa'"
                    @click.stop="toggleTask(item)"
                  >
                    <v-icon size="20">
                      {{
                        item.done
                          ? "mdi-check-circle"
                          : "mdi-check-circle-outline"
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <template v-slot:due_date="{ slotScope: { item } }">
                  <span class="caption">
                    {{ item.due_date ? timeToDate(item.due_date) : "" }}</span
                  >
                </template>

                <template v-slot:priority="{ slotScope: { item } }">
                  <task-priority :readonly="true" :value="item.priority" />
                </template>

                <template v-slot:content="{ slotScope: { item } }">
                  <span class="body-3">{{ item.content }}</span>
                </template>

                <template v-slot:users="{ slotScope: { item } }">
                  <userAvatarGroup
                    :limit="1"
                    style="float: left"
                    :users="item.users"
                    :size="22"
                  />
                </template>
              </paginatedTable>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- TASK DETAILS v-click-outside="clickOutsideDetails" -->
      <v-navigation-drawer
        v-model="showTaskDetails"
        right
        clipped
        stateless
        disable-resize-watcher
        app
        width="740"
        color="light_grey"
      >
        <template v-slot:prepend>
          <v-toolbar height="60" flat>
            <!-- LOADING SKELETON -->
            <template v-if="!editedTask || loadingCard">
              <v-skeleton-loader
                class="skeleton_large_button"
                type="button"
              ></v-skeleton-loader>
              <v-spacer></v-spacer>
              <v-skeleton-loader
                class="skeleton_small_avatar mx-2"
                type="avatar"
              ></v-skeleton-loader>

              <v-skeleton-loader
                class="skeleton_small_avatar px-2"
                type="avatar"
              ></v-skeleton-loader>
            </template>

            <template v-else>
              <!-- Mark task done/undone -->
              <v-btn
                :outlined="!editedTask.done"
                depressed
                :color="editedTask.done ? 'success' : ''"
                small
                @click="toggleTask(editedTask)"
              >
                <v-icon small class="pr-1">mdi-check</v-icon>
                <span>{{
                  editedTask.done
                    ? $t("tasks.completed")
                    : $t("tasks.markComplete")
                }}</span>
              </v-btn>

              <v-spacer></v-spacer>

              <!-- Convert to card -->
              <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="showTaskDetails = false"
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mx-1"
                >
                  <v-icon size="18">mdi-card-bulleted-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("textEditor.convertToCard") }}</span>
            </v-tooltip> -->

              <!-- Delete -->
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="removeFeedItem(editedTaskId)"
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mx-1"
                    color="red"
                  >
                    <v-icon size="18">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tasks.deleteTask") }}</span>
              </v-tooltip>

              <!-- Close side panel -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="showTaskDetails = false"
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mx-1"
                  >
                    <v-icon size="18">mdi-arrow-collapse-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tasks.closeTaskDetails") }}</span>
              </v-tooltip>
            </template>
          </v-toolbar>

          <v-divider></v-divider>
          <div
            class="skeleton_task_details_wrapper"
            v-if="!editedTask || loadingCard"
          >
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </div>

          <div flat class="white pa-2" v-else>
            <div>
              <v-text-field
                v-model="editedTask.content"
                background-color="transparent"
                placeholder="Task Title"
                class="title cardTitlePlaceholder focusedCardEditorField"
                hide-details
                solo
                flat
                autocomplete="off"
                @blur="saveTaskContent"
              >
                <template slot="append"> </template>
              </v-text-field>
            </div>
            <v-row wrap no-gutters class="py-1 px-4">
              <v-col cols="4">
                <span class="body-3">Assigned to:</span>
              </v-col>
              <v-col cols="8">
                <select-users-chip
                  :selectedUsers="editedTask.users"
                  @onUserSelected="addUserToTask(editedTask.id, ...arguments)"
                  @onUserUnselected="
                    removeUserFromTask(editedTask, ...arguments)
                  "
                  xsmall
              /></v-col>
            </v-row>
            <v-row wrap no-gutters class="py-1 px-4">
              <v-col cols="4"><span class="body-3">Due date:</span></v-col>
              <v-col cols="8">
                <select-date-chip
                  xsmall
                  :date="editedTask.due_date"
                  @dateSelected="updateTaskDate(editedTask.id, ...arguments)"
              /></v-col>
            </v-row>

            <v-row wrap no-gutters class="py-1 px-4 pb-4">
              <v-col cols="4"><span class="body-3">Priority:</span></v-col>
              <v-col cols="8">
                <task-priority
                  style="position: relative; top: 5px"
                  :readonly="false"
                  :value="editedTask.priority"
                  @selectedValue="updateTaskPriority(editedTask, ...arguments)"
                  :captionYoffset="18"
              /></v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
        </template>

        <v-card class="px-4 mb-16" flat color="light_grey">
          <template v-if="editedCard && !loadingCard">
            <!-- Followup name and project anme + go to topic button -->
            <v-list-item two-line dense class="lightGreyListItem pt-2">
              <v-list-item-content>
                <v-list-item-title>{{
                  editedCard.follow_up.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class="body-4"
                  v-if="editedCard.follow_up.project_id"
                >
                  <v-icon
                    :color="editedCard.follow_up.project_color"
                    size="12"
                    class="mr-1"
                    >mdi-folder</v-icon
                  >
                  {{ editedCard.follow_up.project_name }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-btn
                color="button_blue"
                dark
                small
                depressed
                target="_blank"
                :to="{
                  name: 'followup_show',
                  params: { id: editedCard.follow_up_id },
                  query: { scrollTo: editedCard.id },
                }"
              >
                {{ $t("tasks.goToTopic") }}
              </v-btn>
            </v-list-item>

            <v-subheader class="body-4 font-weight-bold my-0">
              <v-icon x-small left>mdi-card-bulleted</v-icon>
              {{ $t("tasks.taskBelongsTo") }}</v-subheader
            >
            <cardWrapper
              v-if="editedCard && cardEdit == false"
              :card="editedCard"
              smallerTitle
              @openCardDialog="cardEdit = true"
              @showFile="showFile"
            />
            <cardEditor
              class="mx-2"
              v-if="editedCard && cardEdit == true"
              @closeDialog="cardEdit = false"
              @refreshTasks="refreshIndex++"
              @taskDeleted="onTaskDeleted"
            />
          </template>
        </v-card>
      </v-navigation-drawer>
    </div>

    <v-dialog
      v-model="showFileViewer"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1280"
    >
      <fileViewerColumn
        :showFileViewerCol="true"
        :focusFileViewerCol="true"
        :fileToShow="fileToShow"
        :filesGroupedByCard="filesGroupedByCard"
        v-if="filesGroupedByCard.length && fileToShow"
        @close="fileToShow = null"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import userAvatar from "../shared/userAvatar";
import date from "@/mixins/date";

import cardEditor from "@/components/cards/card_editor.vue";
import cardWrapper from "@/components/cards/card_wrapper.vue";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import paginatedTable from "@/components/shared/paginatedTable";
import PAGINATED_FEED_ITEMS_INDEX from "@/graphql/feed_items/paginated_index.gql";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import CREATE_FEED_ITEM_USER from "@/graphql/feed_item_users/create.gql";
import DESTROY_FEED_ITEM from "@/graphql/feed_items/destroy.gql";
import { mapActions, mapGetters } from "vuex";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import PROJECT_INDEX from "@/graphql/projects/index.gql";
import taskPriority from "./task_priority.vue";
import CARD_SHOW from "@/graphql/cards/show.gql";
import fileViewerColumn from "@/components/file_viewer/file_viewer_column.vue";
import SelectUsersChip from "../shared/selectUsersChip.vue";
import SelectDateChip from "../shared/selectDateChip.vue";
import * as api from "@/services/no-meetings.api";

export default {
  name: "Tasks",
  mixins: [date],
  components: {
    cardEditor,
    cardWrapper,
    headerToolbar,
    paginatedTable,
    userAvatar,
    userAvatarGroup,
    taskPriority,
    fileViewerColumn,
    SelectUsersChip,
    SelectDateChip,
  },
  data() {
    return {
      dialog: false,
      fileToShow: null,
      showFileViewer: false,
      showTaskDetails: false,
      cardEdit: false,
      loadingCard: false,
      loadingRemoveTask: false,
      loadingFeedItemDestroy: false,
      taskDetailsLoading: false,
      noResourceFetched: false,
      search: "",
      refreshIndex: 0,
      editedFollowup: {},
      followupFormProjectFollowup: {},
      projectsLoading: true,
      selectedProjectFilter: "",
      showProjectFiltersMenu: false, // Needed because we need to close this one programmaticaly
      projects: [],
      selectedDoneFilter: 1,
      selectedDoneFilterDefault: 1,
      doneFilters: [
        {
          title: "tasks.allTask",
          value: "all",
        },
        {
          title: "tasks.incompleteTasks",
          value: "incomplete",
        },
        {
          title: "tasks.completedTasks",
          value: "completed",
        },
      ],
      selectedAssignedToFilter: 0,
      selectedAssignedToFilterDefault: 0,
      assignedToFilters: [
        {
          title: "tasks.myTasks",
          value: "myTasks",
        },
        {
          title: "tasks.everyone",
          value: "everyone",
        },
      ],
      selectedPriorityFilter: 0,
      selectedPriorityFilterDefault: 0,
      priorityFilters: [
        {
          title: "tasks.all",
          value: "all",
        },
        {
          title: "tasks.priorities.low",
          value: "low",
        },
        {
          title: "tasks.priorities.medium",
          value: "medium",
        },
        {
          title: "tasks.priorities.high",
          value: "high",
        },
        {
          title: "tasks.priorities.critical",
          value: "critical",
        },
      ],
      selectedFilter: 0,
      staticFilters: [
        {
          title: "tasks.allTask",
          icon: "mdi-format-list-checkbox",
        },
        {
          title: "tasks.myTasks",
          icon: "mdi-format-list-checks",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "selectedOrganisationId",
      "currentUser",
      "editedCardId",
      "editedCard",
      "editedTaskId",
      "editedTask",
      "editedCardFollowUpId",
    ]),
    miniVariant() {
      if (this.$vuetify.breakpoint.smAndDown) return true;
      return false;
    },
    headers() {
      var _cls = this.showTaskDetails
        ? " d-none d-xl-table-cell"
        : " d-none d-md-table-cell";

      var columns = [
        {
          text: this.$t("tasks.done"),
          align: "start",
          sortable: true,
          value: "done",
          width: 78,
        },
        {
          text: this.$t("tasks.task"),
          align: "start",
          sortable: true,
          value: "content",
        },
        // {
        //   text: this.$t("tasks.context"),
        //   align: "start" + _cls,
        //   sortable: true,
        //   value: "card.title",
        // },
        {
          text: this.$t("tasks.assignedTo"),
          align: "start" + _cls,
          sortable: false,
          value: "users",
        },
        {
          text: this.$t("tasks.dueDate"),
          align: "start",
          sortable: true,
          value: "due_date",
        },
        {
          text: this.$t("tasks.priority"),
          align: "start" + _cls,
          sortable: true,
          value: "priority",
        },
      ];

      // Insert created columns at index 2 position when breakpoint is greater than md
      // if (!this.$vuetify.breakpoint.mdAndDown)
      //   columns.splice(2, 0, {
      //     text: this.$t("misc.createdAt"),
      //     align: this.$vuetify.breakpoint.mdAndDown ? "d-none" : "start",
      //     sortable: false,
      //     value: "created_at",
      //   });

      return columns;
    },
    filesGroupedByCard() {
      if (this.editedCard) {
        return [
          {
            card_id: this.editedCard.id,
            card_name: this.editedCard.title
              ? this.editedCard.title
              : this.editedCard._title,
            files: this.editedCard.files,
            updated_at: this.editedCard.updated_at,
          },
        ];
      } else return [];
    },
    filter() {
      let filter = "feed_items.feed_item_type == task";
      if (this.selectedOrganisationId)
        filter += `&& feed_items.organisation_id == '${this.selectedOrganisationId}'`;

      if (this.search) {
        filter += ` && content like '%${this.search}%'`;
      }

      switch (this.selectedDoneFilter) {
        case 1:
          filter += ` && done == 'false'`;
          break;
        case 2:
          filter += ` && done == 'true'`;
          break;
      }

      switch (
        this.currentUser &&
        this.currentUser.id &&
        this.selectedAssignedToFilter
      ) {
        case 0:
          filter += ` && feed_item_users.user_id == '${this.currentUser.id}'`;
          break;
      }

      if (this.selectedPriorityFilter > 0) {
        filter += ` && priority == ${
          this.priorityFilters[this.selectedPriorityFilter].value
        }`;
      }

      if (this.selectedProjectFilter && this.selectedProjectFilter.id) {
        filter += ` && project_id == '${this.selectedProjectFilter.id}'`;
      }

      return filter;
    },
  },
  methods: {
    ...mapActions([
      "openDestroyDialog",
      "addFollowUpCards",
      "setEditedCardId",
      "setEditedTaskId",
      "setEditedCardFollowUpId",
      "connectToFollowUpChannel",
      "disconnectFromFollowUpChannel",
    ]),
    onTaskDeleted(id) {
      if (this.id == this.editedTaskId) this.showTaskDetails = false;
    },
    displayTaskDetails(item) {
      if (this.editedCardId != item.card.id) {
        this.getCard(item.card.id);
      }
      this.setEditedCardId(item.card.id);
      this.setEditedTaskId(item.id);
      this.showTaskDetails = true;
    },
    saveTaskContent() {
      let item = {
        id: this.editedTaskId,
        content: this.editedTask.content,
      };
      this.updateFeedItem(item);
    },
    addUserToTask(task_id, user_id) {
      this.$apollo
        .mutate({
          mutation: CREATE_FEED_ITEM_USER,
          variables: {
            feed_item_id: task_id,
            user_id: user_id,
          },
        })
        .then(() => {
          this.refreshIndex++;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    removeUserFromTask(task, user_id) {
      let feed_item_user_id = task.feed_item_users.find(
        (e) => e.user_id == user_id
      ).id;
      this.destroyFeedItemUser(feed_item_user_id);
    },
    async destroyFeedItemUser(id) {
      if (this.loadingFeedItemDestroy) return;
      try {
        this.loadingFeedItemDestroy = true;
        const { data } = await api.destroy(id, "feed_item_user");
        this.loadingFeedItemDestroy = false;
      } catch (err) {
        this.loadingFeedItemDestroy = false;
        this.sendError(err);
        console.error(err);
      }
    },
    async removeFeedItem(id) {
      let msg = this.$t("tasks.removeAlertMessage").replace(
        "<task_content>",
        this.editedTask.content || ""
      );

      this.openDestroyDialog({
        id: id,
        model: "feed_item",
        onDestroy: () => {
          this.showTaskDetails = false;
          this.refreshIndex++;
        },
        title: this.$t("tasks.removeAlertTitle"),
        message: msg,
      });
    },
    updateTaskDate(task_id, date) {
      let item = {
        id: task_id,
        due_date: new Date(date).getTime(),
      };
      this.updateFeedItem(item);
    },
    updateTaskPriority(task, priority) {
      task.priority = priority;
      this.updateFeedItem(task);
    },
    updateFeedItem(item) {
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: item,
        })
        .then(() => {
          this.refreshIndex++;
        });
    },
    getCard(card_id) {
      if (this.loadingCard) return;

      if (this.editedCardFollowUpId) {
        this.disconnectFromFollowUpChannel(this.editedCardFollowUpId);
        this.setEditedCardFollowUpId(null);
      }

      this.loadingCard = true;
      this.$apollo
        .query({
          query: CARD_SHOW,
          variables: {
            id: card_id,
          },
        })
        .then(({ data }) => {
          this.setEditedCardFollowUpId(data.card.follow_up_id);
          this.connectToFollowUpChannel(data.card.follow_up_id);
          this.addFollowUpCards({
            follow_up_id: data.card.follow_up_id,
            cards: [data.card],
          });
          console.log(data);
          this.loadingCard = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCard = false;
        });
    },
    showFile(file) {
      this.fileToShow = file;
    },
    destroyCard(card) {
      let msg = this.$t("cards.removeAlertMessageNoTitle");
      if (card.title)
        msg = this.$t("cards.removeAlertMessage").replace(
          "<card_title>",
          card.title
        );

      this.openDestroyDialog({
        id: card.id,
        model: "card",
        onDestroy: () => {
          this.cardDialog = false;
        },
        title: this.$t("cards.removeAlertTitle"),
        message: msg,
      });
    },
    clickOutsideDetails() {
      if (this.showTaskDetails) this.showTaskDetails = false;
    },
    getProjects() {
      this.$apollo
        .query({
          query: PROJECT_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          // console.log("project gets");
          // console.log(data);
          this.projects = data.projects;
          this.projectsLoading = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    getGraphqlQuery() {
      return PAGINATED_FEED_ITEMS_INDEX;
    },
    toggleTask(task) {
      /* Toggle task optimistic */
      task.done = !task.done;
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: task.id,
            done: task.done,
          },
        })
        .then(({ data }) => {
          this.refreshIndex++;
        })
        .catch((error) => {
          /* Cancel optimistic if something went wrong */
          task.done = !task.done;
          this.sendError(error);
          console.log(error);
        });
    },
  },
  created() {
    this.getProjects();
    document.title = this.$t("navigationMenu.tasks");
    this.$amplitude.getInstance().logEvent("Page: Tasks");
  },
  onDestroy() {
    if (this.editedCardFollowUpId) {
      this.disconnectFromFollowUpChannel(this.editedCardFollowUpId);
      this.setEditedCardFollowUpId(null);
    }
  },
  watch: {
    search() {
      this.noResourceFetched = false;
    },
    editedCardId() {
      this.cardEdit = false;
    },
    fileToShow() {
      if (!this.fileToShow) this.showFileViewer = false;
      else this.showFileViewer = true;
    },
    showFileViewer() {
      if (!this.showFileViewer) this.fileToShow = null;
    },
  },
};
</script>

<style >
.theme--light.v-application {
  background-color: #fff !important;
}
</style>
<style scoped>
.task_overview_grid {
  /* display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 36px 36px 36px;
  grid-gap: 0px;

  padding-bottom: 2px;
  padding-top: 2px;
  align-items: center; */
}
</style>

