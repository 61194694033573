var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",staticStyle:{"height":"100vh","background-color":"white"},attrs:{"fluid":""}},[_c('headerToolbar',{attrs:{"color":"white","divider":""},scopedSlots:_vm._u([{key:"toolbarTitle",fn:function(){return [_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("navigationMenu.tasks")))])]},proxy:true}])}),(_vm.selectedOrganisationId)?_c('div',{staticClass:"pa-0 pl-0",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-2",staticStyle:{"height":"60px"},attrs:{"wrap":"","no-gutters":"","align":"center"}},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium mr-4",attrs:{"small":"","text":"","color":_vm.selectedDoneFilter != _vm.selectedDoneFilterDefault ? 'button_blue' : 'button_text_grey'}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(_vm._s(_vm.$t(_vm.doneFilters[_vm.selectedDoneFilter].title))+" ")],1)]}}],null,false,3307570086)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},_vm._l((_vm.doneFilters),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectedDoneFilter = index}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[(_vm.selectedDoneFilter == index)?_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium mr-4",attrs:{"small":"","text":"","color":_vm.selectedAssignedToFilter != _vm.selectedAssignedToFilterDefault ? 'button_blue' : 'button_text_grey'}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-account-outline")]),_vm._v(_vm._s(_vm.$t(_vm.assignedToFilters[_vm.selectedAssignedToFilter].title))+" ")],1)]}}],null,false,698608126)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},_vm._l((_vm.assignedToFilters),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectedAssignedToFilter = index}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[(_vm.selectedAssignedToFilter == index)?_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","min-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium mr-4",attrs:{"small":"","text":"","color":_vm.selectedPriorityFilter != _vm.selectedPriorityFilterDefault ? 'button_blue' : 'button_text_grey'}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-filter-variant")]),_vm._v(_vm._s(_vm.$t("tasks.priority") + ": " + _vm.$t(_vm.priorityFilters[_vm.selectedPriorityFilter].title))+" ")],1)]}}],null,false,3316207777)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},_vm._l((_vm.priorityFilters),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectedPriorityFilter = index}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[(_vm.selectedPriorityFilter == index)?_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))]),(index > 0)?_c('task-priority',{attrs:{"value":item.value,"readonly":""}}):_vm._e()],1)}),1)],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","min-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-medium mr-4",class:{
                  'ml-2': _vm.selectedProjectFilter != '',
                },attrs:{"small":"","text":_vm.selectedProjectFilter == '',"dark":_vm.selectedProjectFilter != '',"depressed":"","color":_vm.selectedProjectFilter
                    ? _vm.selectedProjectFilter.color
                    : 'button_text_grey'}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.selectedProjectFilter == "" ? "mdi-folder-outline" : "mdi-folder"))]),_vm._v(_vm._s(_vm.selectedProjectFilter ? _vm.selectedProjectFilter.name : _vm.$t("tasks.project"))+" ")],1)]}}],null,false,1070283199),model:{value:(_vm.showProjectFiltersMenu),callback:function ($$v) {_vm.showProjectFiltersMenu=$$v},expression:"showProjectFiltersMenu"}},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_vm._l((_vm.projects),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectedProjectFilter && _vm.selectedProjectFilter.id == item.id
                    ? (_vm.selectedProjectFilter = '')
                    : (_vm.selectedProjectFilter = item)}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[(
                      _vm.selectedProjectFilter &&
                      _vm.selectedProjectFilter.id == item.id
                    )?_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-check")]):_vm._e()],1),_c('v-list-item-title',[_c('v-icon',{staticClass:"pr-2",attrs:{"small":"","color":item.color}},[_vm._v("mdi-folder")]),_vm._v(_vm._s(item.name)+" ")],1),_c('v-list-item-icon',{staticClass:"my-0"},[(
                      _vm.selectedProjectFilter &&
                      _vm.selectedProjectFilter.id == item.id
                    )?_c('v-btn',{staticStyle:{"top":"2px"},attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();_vm.selectedProjectFilter = '';
                      _vm.showProjectFiltersMenu = false;}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1)],1)}),(_vm.projects.length == 0)?_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("projects.noProjects")))])],1)],1):_vm._e()],2)],1),_c('v-spacer'),_c('v-text-field',{staticClass:"denseTextField mr-4",attrs:{"label":_vm.$t('tasks.search'),"outlined":"","rounded":"","solo":"","flat":"","dense":"","prepend-inner-icon":"mdi-magnify","width":"200","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4"}),_c('v-row',{attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{staticClass:"pa-0 pt-2 px-4",staticStyle:{"height":"calc(100vh - 135px)","overflow":"auto"},attrs:{"cols":"12"}},[(_vm.currentUser && _vm.currentUser.id)?_c('paginatedTable',{attrs:{"dense":true,"headers":_vm.headers,"graphqlQuery":_vm.getGraphqlQuery(),"refresh":_vm.refreshIndex,"resourceName":"feed_items","forcedFooterText":_vm.$t('tasks.tasks'),"itemsPerPageAtInit":50,"filter":_vm.filter,"useCustomEmptySlot":"","customSlots":[
                'content',
                'done',
                //        'status',
                //        'start_date',
                //        'end_date',
                //        'favorite',
                'due_date',
                'action',
                'users',

                'priority' ],"cursorAsPointer":""},on:{"clickOnRow":_vm.displayTaskDetails,"noResourceFetched":function($event){_vm.noResourceFetched = true}},scopedSlots:_vm._u([{key:"done",fn:function(ref){
              var item = ref.slotScope.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":item.done ? 'success' : '#aaa'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleTask(item)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(item.done ? "mdi-check-circle" : "mdi-check-circle-outline")+" ")])],1)]}},{key:"due_date",fn:function(ref){
              var item = ref.slotScope.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.due_date ? _vm.timeToDate(item.due_date) : ""))])]}},{key:"priority",fn:function(ref){
              var item = ref.slotScope.item;
return [_c('task-priority',{attrs:{"readonly":true,"value":item.priority}})]}},{key:"content",fn:function(ref){
              var item = ref.slotScope.item;
return [_c('span',{staticClass:"body-3"},[_vm._v(_vm._s(item.content))])]}},{key:"users",fn:function(ref){
              var item = ref.slotScope.item;
return [_c('userAvatarGroup',{staticStyle:{"float":"left"},attrs:{"limit":1,"users":item.users,"size":22}})]}}],null,false,256160358)}):_vm._e()],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"right":"","clipped":"","stateless":"","disable-resize-watcher":"","app":"","width":"740","color":"light_grey"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-toolbar',{attrs:{"height":"60","flat":""}},[(!_vm.editedTask || _vm.loadingCard)?[_c('v-skeleton-loader',{staticClass:"skeleton_large_button",attrs:{"type":"button"}}),_c('v-spacer'),_c('v-skeleton-loader',{staticClass:"skeleton_small_avatar mx-2",attrs:{"type":"avatar"}}),_c('v-skeleton-loader',{staticClass:"skeleton_small_avatar px-2",attrs:{"type":"avatar"}})]:[_c('v-btn',{attrs:{"outlined":!_vm.editedTask.done,"depressed":"","color":_vm.editedTask.done ? 'success' : '',"small":""},on:{"click":function($event){return _vm.toggleTask(_vm.editedTask)}}},[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-check")]),_c('span',[_vm._v(_vm._s(_vm.editedTask.done ? _vm.$t("tasks.completed") : _vm.$t("tasks.markComplete")))])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeFeedItem(_vm.editedTaskId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,false,2982074726)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.deleteTask")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.showTaskDetails = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-arrow-collapse-right")])],1)]}}],null,false,1609178675)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.closeTaskDetails")))])])]],2),_c('v-divider'),(!_vm.editedTask || _vm.loadingCard)?_c('div',{staticClass:"skeleton_task_details_wrapper"},[_c('v-skeleton-loader',{attrs:{"type":"article"}})],1):_c('div',{staticClass:"white pa-2",attrs:{"flat":""}},[_c('div',[_c('v-text-field',{staticClass:"title cardTitlePlaceholder focusedCardEditorField",attrs:{"background-color":"transparent","placeholder":"Task Title","hide-details":"","solo":"","flat":"","autocomplete":"off"},on:{"blur":_vm.saveTaskContent},model:{value:(_vm.editedTask.content),callback:function ($$v) {_vm.$set(_vm.editedTask, "content", $$v)},expression:"editedTask.content"}},[_c('template',{slot:"append"})],2)],1),_c('v-row',{staticClass:"py-1 px-4",attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"body-3"},[_vm._v("Assigned to:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('select-users-chip',{attrs:{"selectedUsers":_vm.editedTask.users,"xsmall":""},on:{"onUserSelected":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.addUserToTask.apply(void 0, [ _vm.editedTask.id ].concat( argsArray ))},"onUserUnselected":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.removeUserFromTask.apply(void 0, [ _vm.editedTask ].concat( argsArray ))}}})],1)],1),_c('v-row',{staticClass:"py-1 px-4",attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"body-3"},[_vm._v("Due date:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('select-date-chip',{attrs:{"xsmall":"","date":_vm.editedTask.due_date},on:{"dateSelected":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateTaskDate.apply(void 0, [ _vm.editedTask.id ].concat( argsArray ))}}})],1)],1),_c('v-row',{staticClass:"py-1 px-4 pb-4",attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"body-3"},[_vm._v("Priority:")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('task-priority',{staticStyle:{"position":"relative","top":"5px"},attrs:{"readonly":false,"value":_vm.editedTask.priority,"captionYoffset":18},on:{"selectedValue":function($event){
              var i = arguments.length, argsArray = Array(i);
              while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateTaskPriority.apply(void 0, [ _vm.editedTask ].concat( argsArray ))}}})],1)],1)],1),_c('v-divider')]},proxy:true}],null,false,425862853),model:{value:(_vm.showTaskDetails),callback:function ($$v) {_vm.showTaskDetails=$$v},expression:"showTaskDetails"}},[_c('v-card',{staticClass:"px-4 mb-16",attrs:{"flat":"","color":"light_grey"}},[(_vm.editedCard && !_vm.loadingCard)?[_c('v-list-item',{staticClass:"lightGreyListItem pt-2",attrs:{"two-line":"","dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.editedCard.follow_up.name))]),(_vm.editedCard.follow_up.project_id)?_c('v-list-item-subtitle',{staticClass:"body-4"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.editedCard.follow_up.project_color,"size":"12"}},[_vm._v("mdi-folder")]),_vm._v(" "+_vm._s(_vm.editedCard.follow_up.project_name)+" ")],1):_vm._e()],1),_c('v-btn',{attrs:{"color":"button_blue","dark":"","small":"","depressed":"","target":"_blank","to":{
                name: 'followup_show',
                params: { id: _vm.editedCard.follow_up_id },
                query: { scrollTo: _vm.editedCard.id },
              }}},[_vm._v(" "+_vm._s(_vm.$t("tasks.goToTopic"))+" ")])],1),_c('v-subheader',{staticClass:"body-4 font-weight-bold my-0"},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-card-bulleted")]),_vm._v(" "+_vm._s(_vm.$t("tasks.taskBelongsTo")))],1),(_vm.editedCard && _vm.cardEdit == false)?_c('cardWrapper',{attrs:{"card":_vm.editedCard,"smallerTitle":""},on:{"openCardDialog":function($event){_vm.cardEdit = true},"showFile":_vm.showFile}}):_vm._e(),(_vm.editedCard && _vm.cardEdit == true)?_c('cardEditor',{staticClass:"mx-2",on:{"closeDialog":function($event){_vm.cardEdit = false},"refreshTasks":function($event){_vm.refreshIndex++},"taskDeleted":_vm.onTaskDeleted}}):_vm._e()]:_vm._e()],2)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"1280"},model:{value:(_vm.showFileViewer),callback:function ($$v) {_vm.showFileViewer=$$v},expression:"showFileViewer"}},[(_vm.filesGroupedByCard.length && _vm.fileToShow)?_c('fileViewerColumn',{attrs:{"showFileViewerCol":true,"focusFileViewerCol":true,"fileToShow":_vm.fileToShow,"filesGroupedByCard":_vm.filesGroupedByCard},on:{"close":function($event){_vm.fileToShow = null}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }